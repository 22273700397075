// ==========[ GENERAL ]==========

* {
  font-feature-settings  : "kern"1;
  font-family            : $ff-proxima-nova;
  font-kerning           : normal;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin : 0;
  padding: 0;
}

h1 {
  color         : $blue;
  text-transform: uppercase;
  font-size     : 50px;
  font-weight   : bold;
  line-height   : 50px;
  letter-spacing: 0.15em;
}

h2 {
  font-size  : 36px;
  font-weight: bold;
  line-height: 54px;
  color      : $blue;
}

h3 {
  color      : $blue;
  font-size  : 24px;
  font-weight: bold;
  line-height: 36px;
}

h4 {
  font-size  : 18px;
  font-weight: normal;
  line-height: 27px;
}

h5 {
  font-size  : 16px;
  font-weight: bold;
  line-height: 32px;
}

.cms,
p {
  color      : $grey;
  font-size  : 16px;
  line-height: 32px;

  &.small-text {
    font-size  : 14px;
    font-weight: normal;
    line-height: 28px;
  }

  a {
    color          : $yellow;
    text-decoration: underline;

    &:hover {
      color          : darken($yellow, 4%);
      text-decoration: none;
    }
  }
}

.cms {
  h2 {
    color: $blue;
  }

  img {
    border: 4px solid $yellow;
  }
}

// ==========[ FORM ]==========

form {
  .form-group {
    .form-control {
      min-height   : 45px;
      padding-right: 20px;
      padding-left : 20px;
      color        : $grey;
      border       : 1px solid #E5E5E5;
      border-radius: 0;
      font-size    : 14px;
      font-weight  : normal;
      line-height  : 28px;

      &::placeholder {
        color: $grey;
      }

      &:focus {
        border    : 1px solid $yellow;
        outline   : 0;
        box-shadow: none;
      }
    }
  }
}

// ==========[ HEADER || NAVIGATION ]==========

header {
  z-index         : 999;
  position        : fixed;
  top             : 0;
  right           : 0;
  left            : 0;
  background-color: $white;
  .right-nav {
    z-index : 999;
    position: absolute;
    top     : 0;
    right   : 16px;

    .lang-picker {
      margin-right  : 20px;
      color         : $blue;
      text-transform: uppercase;
      font-size     : 12px;
      font-weight   : bold;
      line-height   : 12px;
      letter-spacing: 0.15em;

      &:hover {
        color          : $yellow;
        text-decoration: none;
      }
    }
  }

  .navbar {
    @include softshadow;
    @include quart-transition(400ms);
    height: 200px;
    .navbar-brand {
      img {
        width: 100%;
      }

      &.big-brand {
        width: 190px;
      }

      &.small-brand {
        display: none;
        width  : 60px;
      }
    }

    .navbar-nav {
      @include quart-transition(400ms);
      padding-top: 45px;

      .nav-item {
        margin : 0;
        padding: 0;

        .nav-link {
          margin-right  : 30px;
          padding       : 0;
          color         : $blue;
          text-transform: uppercase;
          font-size     : 14px;
          font-weight   : bold;
          line-height   : 14px;
          letter-spacing: 0.15em;

          &:hover {
            color: $yellow;
          }
        }

        &:last-child {
          .nav-link {
            margin-right: 0;
          }
        }

        &.active {
          .nav-link {
            color: $yellow;
          }
        }
      }
    }

    &.scrolled {
      height: 100px;

      .right-nav {
        display: none;
      }

      .navbar-brand {
        &.big-brand {
          display: none;
        }

        &.small-brand {
          display: block;
        }
      }

      .navbar-nav {
        padding-top: 0;
      }
    }
  }
}

// ==========[ FOOTER ]==========

footer {
  h5 {
    color      : $blue;
    font-size  : 16px;
    font-weight: bold;
    line-height: 32px;
  }

  ul {
    margin         : 0;
    padding        : 0;
    list-style-type: none;

    li {
      margin-bottom: 4px;

      a {
        color         : $grey;
        text-transform: uppercase;
        font-size     : 12px;
        font-weight   : bold;
        line-height   : 12px;
        letter-spacing: 0.15em;

        &.active,
        &:hover {
          color          : $yellow;
          text-decoration: none;
        }
      }
    }
  }

  .footer-social {
    @include quart-transition(400ms);
    display         : flex;
    width           : 30px;
    height          : 30px;
    color           : $white;
    border-radius   : 999px;
    background-color: $blue;
    align-items     : center;
    justify-content : center;

    &:hover {
      background-color: $yellow;
      text-decoration : none;
    }
  }
}

.sub-footer {
  padding-top     : 15px;
  padding-bottom  : 15px;
  background-color: $grey-light;

  p {
    a {
      color: $grey;

      &:hover {
        color: $grey-dark;
      }
    }
  }
}

// ==========[ HOME ]==========

.home-header {
  height     : 800px;
  margin-top : 125px;
  padding-top: 75px;
  overflow-y : hidden;
  transform  : skewY(-4deg);

  .home-header-inner {
    transform: skewY(4deg);
  }

  .slide-wrapper,
  .slide,
  .slides {
    height: 800px;
  }

  .slide {
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;

    .inner {
      display         : flex;
      height          : 100%;
      background-color: transparentize($blue, .4);
      align-items     : flex-start;
      flex-direction  : column;
      justify-content : center;

      .container {
        z-index: 9999;
      }

      h2 {
        max-width: 66%;
        color    : $yellow;
      }

      .cms {
        max-width: 66%;
        color    : $white;
      }

      .waveWrapper {
        position: absolute;
        top     : 50%;
        right   : 0;
        bottom  : 0;
        left    : 0;
        margin  : auto;
        overflow: hidden;
      }

      .waveWrapperInner {
        position        : absolute;
        bottom          : -1px;
        width           : 100%;
        height          : 50%;
        overflow        : hidden;
        background-color: transparent;
      }

      .bgTop {
        z-index: 15;
        opacity: 0.5;
      }

      .bgMiddle {
        z-index: 10;
        opacity: 0.75;
      }

      .bgBottom {
        z-index: 5;
      }

      .wave {
        position           : absolute;
        left               : 0;
        width              : 200%;
        height             : 100%;
        background-repeat  : repeat no-repeat;
        background-position: 0 bottom;
        transform-origin   : center bottom;
      }

      .waveTop {
        background-size: 50% 180px;
      }

      .waveAnimation .waveTop {
        -webkit-animation      : move-wave 3s;
        animation              : move-wave 3s;
        -webkit-animation-delay: 1s;
        animation-delay        : 1s;
      }

      .waveMiddle {
        background-size: 50% 200px;
      }

      .waveAnimation .waveMiddle {
        animation: move_wave 10s linear infinite;
      }

      .waveBottom {
        background-size: 50% 180px;
      }

      .waveAnimation .waveBottom {
        animation: move_wave 15s linear infinite;
      }
    }
  }
}

.vestigingen-map {
  width : 100%;
  height: 500px;
}

.cta {
  display        : flex;
  padding        : 30px 40px;
  border         : 4px solid $yellow;
  align-items    : center;
  justify-content: space-between;

  h2 {
    color: $blue;
  }

  input {
    height          : 45px;
    padding-right   : 20px;
    padding-left    : 20px;
    color           : $grey;
    border          : 1px solid #DDDDDD;
    background-color: transparent;
    box-shadow      : none;
    font-size       : 16px;
    font-weight     : normal;
    flex            : 1 0 auto;
    line-height     : 32px;

    &:focus {
      border    : 1px solid $grey;
      outline   : 0;
      box-shadow: none;
    }
  }
}

.nieuws-grid {

  .grid-item,
  .grid-sizer {
    width: calc(33% - 17.5px);
  }
}

.quote {
  &.bg-yellow {
    h2 strong {
      color: $blue;
    }
  }

  &.bg-blue {
    h2 strong {
      color: $yellow;
    }
  }
}

.cms {
  &.cols-2 {
    column-count: 2;
    column-gap  : 30px;
  }

  &.cols-3 {
    column-count: 3;
    column-gap  : 30px;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 1.5rem !important;
  }

  img {
    max-width: 100%;
    height   : auto;
  }
}