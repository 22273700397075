// ==========[ FONTS ]==========

$ff-proxima-nova : 'Proxima Nova', sans-serif;

// ==========[ COLORS ]==========

$white         : #FFFFFF;
$grey-light    : #F8F8F8;
$grey          : #AAAAAA;
$grey-dark     : #414042;
$black         : #000000;

$blue          : #005EA8;
$yellow        : #F9B200;

// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

// ==========[ KEYFRAMES ]==========

@keyframes move_wave {
  0% {
    transform : translateX(0) translateZ(0) scaleY(1);
  }

  50% {
    transform : translateX(-25%) translateZ(0) scaleY(0.55);
  }

  100% {
    transform : translateX(-50%) translateZ(0) scaleY(1);
  }
}
