// ==========[ BUTTONS ]==========

.btn {
  padding        : 16px 20px 12px;
  border-radius  : 0;
  text-transform : uppercase;
  font-size      : 14px;
  font-weight    : bold;
  line-height    : 14px;
  letter-spacing : .15em;

  &.btn-small {
    padding     : 12px 20px 8px;
    font-size   : 12px;
    line-height : 12px;
  }
}

.btn-yellow {
  color            : $white;
  border           : 2px solid $yellow;
  background-color : $yellow;

  &:hover {
    color            : $white;
    border           : 2px solid darken($yellow, 4%);
    background-color : darken($yellow, 4%);
  }
}

.btn-blue {
  color            : $white;
  border           : 2px solid $blue;
  background-color : $blue;

  &:hover {
    color            : $white;
    border           : 2px solid lighten($blue, 6%);
    background-color : lighten($blue, 6%);
  }
}

.btn-blue-border {
  color            : $white;
  border           : 2px solid $blue;
  background-color : transparent;

  &:hover {
    color            : $white;
    border           : 2px solid lighten($blue, 6%);
    background-color : lighten($blue, 6%);
  }

  .blocks &{
  	color : $blue;

  	&:hover{
  		color: $white;
  	}
  }
}

.btn-yellow-border {
  color            : $white;
  border           : 2px solid $yellow;
  background-color : transparent;

  &:hover {
    color            : $white;
    border           : 2px solid darken($yellow, 4%);
    background-color : darken($yellow, 4%);
  }
}

.btn-icon {
  display         : flex;
  height          : 100%;
  color           : $blue;
  align-items     : center;
  font-weight     : 900;
  flex-direction  : column;
  justify-content : center;

  &:hover {
    color           : $yellow;
    text-decoration : none;
  }
}

.btn-back {
  color          : $grey;
  text-transform : uppercase;
  font-size      : 14px;
  font-weight    : bold;
  line-height    : 14px;
  letter-spacing : 0.15em;

  &:hover {
    color           : $yellow;
    text-decoration : none;
  }
}

// ==========[ UTILITY CLASS ]==========

.skewed {
  transform : skewY(-4deg);

  .skewed-inner {
    padding-top    : 100px;
    padding-bottom : 100px;
    transform      : skewY(4deg);
  }
}

.title-divider {
  width               : 100px;
  height              : 6px;
  background-image    : url(/dist/assets/images/wiggled_line.png);
  background-repeat   : no-repeat;
  background-position : center;
}

.full-divider {
  width               : 100%;
  height              : 6px;
  background-image    : url(/dist/assets/images/wiggled_line.png);
  background-repeat   : repeat-x;
  background-position : center;
}

.star-divider {
  width             : 58px;
  height            : 16px;
  background-image  : url(/dist/assets/images/star_divider.png);
  background-repeat : no-repeat;
}

.bg-yellow {
  background : $yellow;

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color : $white;
  }
}

.bg-blue {
  background-color : $blue;

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color : $white;
  }
}

.c-blue {
  color : $blue;
}

.c-yellow {
  color : $yellow;
}

.header-margin {
  margin-top : 200px;
}

.date-nieuws {
  color          : $yellow;
  text-align     : center;
  text-transform : uppercase;
  font-size      : 14px;
  font-weight    : bold;
  line-height    : 14px;
  letter-spacing : 0.15em;
}

.sharing {
  display         : flex;
  align-items     : center;
  justify-content : flex-start;

  h5 {
    color          : $grey;
    text-transform : uppercase;
    font-size      : 14px;
    font-weight    : bold;
    line-height    : 14px;
    letter-spacing : 0.15em;
  }

  a {
    color           : $grey;
    text-decoration : none;
    font-size       : 18px;

    &:hover {
      color : $yellow;
    }
  }
}

.range-item {
  padding          : 4px 20px 3px;
  color            : $white;
  border-radius    : 999px;
  background-color : $yellow;
  font-size        : 16px;
  font-weight      : bold;
  line-height      : 32px;
}

// ==========[ BLOCKS || ITEMS ]==========

.marker-item {
  display         : flex;
  align-items     : center;
  justify-content : flex-start;

  .marker-icon {
    width             : 16px;
    height            : 16px;
    background-repeat : no-repeat;
    background-size   : auto 100%;
  }

  &.blue {
    h5 {
      color : $blue;
    }
  }

  &.yellow {
    h5 {
      color : $yellow;
    }
  }
}

.nieuws-item {
  .image {
    position : relative;

    .inner {
      @include          quart-transition(400ms);
      display         : flex;
      position        : absolute;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;
      box-shadow      : inset 0px 0px 0px 0px rgba(249,178,0,1);
      align-items     : center;
      justify-content : center;

      .button {
        @include           quart-transition(400ms);
        display          : flex;
        width            : 50px;
        height           : 50px;
        color            : $white;
        border-radius    : 999px;
        background-color : $yellow;
        align-items      : center;
        justify-content  : center;
      }
    }
  }

  .content {
    padding : 30px 10px;

    h4 {
      @include      quart-transition(400ms);
      color       : $blue;
      font-size   : 18px;
      font-weight : bold;
      line-height : 27px;
    }

    h6 {
      color          : $yellow;
      text-transform : uppercase;
      font-size      : 14px;
      font-weight    : bold;
      line-height    : 14px;
      letter-spacing : 0.15em;
    }
  }

  &:hover {
    text-decoration : none;

    .image {
      .inner {
        box-shadow : inset 0px 0px 0px 4px rgba(249,178,0,1);

        .button {
          @include    softshadow;
          transform : scale(1.1);
        }
      }
    }

    .content {
      h4 {
        color : $yellow;
      }
    }
  }
}

.getuigenis-item {
  .avatar-outer {
    width         : 165px;
    height        : 165px;
    padding       : 4px;
    border        : 3px solid $blue;
    border-radius : 999px;

    .avatar-inner {
      width               : 100%;
      height              : 100%;
      border              : 3px solid $blue;
      border-radius       : 999px;
      background-repeat   : no-repeat;
      background-position : center;
      background-size     : cover;
    }
  }

  h4 {
    color       : $blue;
    font-size   : 18px;
    font-weight : bold;
    line-height : 27px;
  }
}

.voordeel-item {
  display         : flex;
  align-items     : flex-start;
  justify-content : flex-start;

  img {
    margin-right : 10px;
  }

  .cms {
    margin-top : -4px;
  }
}

.vestiging-item {
  @include     quart-transition(400ms);
  display    : block;
  padding    : 40px;
  box-shadow : inset 0 0 0 1px #D6D6D6;

  &:hover {
    box-shadow      : inset 0 0 0 4px $yellow;
    text-decoration : none;
  }
}

.horeca-item {
  @include          quart-transition(400ms);
  display         : flex;
  margin-top      : 120px;
  padding-right   : 40px;
  padding-bottom  : 40px;
  padding-left    : 40px;
  border          : 1px solid #D6D6D6;
  align-items     : center;
  flex-direction  : column;
  justify-content : center;

  .top-half {
    @include                  quart-transition(400ms);
    display                 : flex;
    width                   : 180px;
    height                  : 90px;
    margin-top              : -90px;
    margin-bottom           : 75px;
    padding                 : 10px 50px 0 50px;
    border                  : 1px solid #D6D6D6;
    border-bottom           : 0;
    border-top-left-radius  : 999px;
    border-top-right-radius : 999px;
    background-color        : $white;
    align-items             : center;
    justify-content         : center;
    
    img {
      max-width  : 100%;
      margin-top : 40px;
    }
  }

  &:hover {
    padding-right   : 37px;
    padding-bottom  : 37px;
    padding-left    : 37px;
    border          : 4px solid $yellow;
    text-decoration : none;

    .top-half {
      padding       : 7px 47px 0 47px;
      border        : 4px solid $yellow;
      border-bottom : 0;
    }
  }
}

// ==========[ IMAGES ]==========

.image-with-hover {
  @include              quart-transition(400ms);
  display             : block;
  position            : relative;
  width               : 100%;
  background-repeat   : no-repeat;
  background-position : center;
  background-size     : cover;
  box-shadow          : inset 0 0 0 0 $yellow;

  .button {
    @include           quart-transition(400ms);
    display          : flex;
    position         : absolute;
    top              : calc(50% - 25px);
    left             : calc(50% - 25px);
    width            : 50px;
    height           : 50px;
    color            : $white;
    opacity          : 0;
    border-radius    : 9999px;
    background-color : $yellow;
    align-items      : center;
    justify-content  : center;
    transform        : scale(.75);
  }

  &:hover {
    box-shadow : inset 0 0 0 4px $yellow;
    cursor     : pointer;

    .button {
      opacity   : 1;
      transform : scale(1);
    }
  }

  &.wide {
    padding-top : 55%;
  }

  &.thirds {
    padding-top : 66%;
  }

  &.square {
    padding-top : 100%;
  }
}
