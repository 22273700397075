// Extra small devices (portrait phones, less than 576px)

@media
  (max-width : 575.98px) {
  .off-canvas-nav {
    right : -100vw;
    width : 100vw;
  }
}

// Small devices (landscape phones, less than 768px)

@media
  (max-width : 767.98px) {
  .home-header {
    height : 600px;

    .slide-wrapper,
    .slide,
    .slides {
      height : 600px;
    }

    .slide {
      .inner {
        h2 {
          max-width   : 100%;
          font-size   : 24px;
          line-height : 29px;
        }

        .cms {
          max-width : 100%;
        }
      }
    }
  }

  h1 {
    font-size   : 36px;
    line-height : 36px;
  }

  .cta {
    padding : 30px 30px !important;
  }

  .nieuws-grid {
    .grid-item,
    .grid-sizer {
      width : 100%;
    }
  }

  .header-margin {
    margin-top : 100px;
  }
  .cms {
    &.cols-2 {
      column-count : 1;
      column-gap   : 0;
    }

    &.cols-3 {
      column-count : 1;
      column-gap   : 0;
    }
  }
}

// Medium devices (tablets, less than 992px)

@media
  (max-width : 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right : 15px;
    padding-left  : 15px;
  }

  .navbar {
    .right-nav {
      display : none;
    }
  }

  .home-header {
    height      : 600px;
    padding-top : 25px;

    .slide-wrapper,
    .slide,
    .slides {
      height : 600px;
    }
    .slide .inner {
      .bgTop, .bgMiddle, .bgBottom{
        display: none;
      }
    }
  }

  .cta {
    padding        : 30px 60px;
    flex-direction : column;

    input {
      width : 100%;
    }
  }
}

// Large devices (desktops, less than 1200px)

@media
  (max-width : 1199.98px) {
}
