// ==========[ EDIT BUTTON ]==========

.edit__page__button {
  @include           quart-transition(400ms);
  display          : flex;
  position         : fixed;
  right            : 20px;
  bottom           : 20px;
  width            : 50px;
  height           : 50px;
  color            : #FFF;
  border-radius    : 999px;
  background-color : #55efc4;
  align-items      : center;
  font-size        : 18px;
  justify-content  : center;

  &:hover {
    @include           softshadow;
    color            : #55efc4;
    background-color : #FFF;
  }
}

// ==========[ 404 PAGE ]==========

.wrapper__404 {
  display             : block;
  position            : relative;
  width               : 100vw;
  height              : 100vh;
  background-image    : url('/dist/assets/images/404/404-bg.gif');
  background-repeat   : no-repeat;
  background-position : center;
  background-size     : cover;

  .inner__404 {
    display         : flex;
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    padding         : 40px;
    background      : linear-gradient(
                        45deg,
                        rgba(60, 146, 206, 0.5) 0%,
                        rgba(24, 187, 185, 0.5) 100%
                      );
    align-items     : center;
    flex-direction  : column;
    justify-content : center;

    .title__404 {
      margin-bottom  : 10px;
      color          : $white;
      text-transform : uppercase;
      font-family    : 'Montserrat',
                       Helvetica,
                       sans-serif;
      font-size      : 110px;
      font-weight    : 700;
      line-height    : 110px;
      letter-spacing : -.05em;
    }

    .text__404 {
      margin-bottom : 40px;
      color         : $white;
      text-align    : center;
      font-family   : 'Esteban',
                      serif;
      font-size     : 26px;
      line-height   : 35px;

      a {
        color           : $white;
        text-decoration : underline;

        &:hover {
          color : #55efc4;
        }
      }
    }

    .button__404 {
      @include           quart-transition(400ms);
      padding          : 15px 26px;
      color            : $grey-dark;
      border-radius    : 999px;
      background-color : $white;
      font-family      : 'Montserrat',
                         Helvetica,
                         sans-serif;
      font-size        : 15px;
      letter-spacing   : -.025em;

      i,
      svg {
        margin-right : 10px;
        font-size    : 12px;
        transition   : margin-right 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }

      &:hover {
        color            : $white;
        background-color : #55efc4;
        text-decoration  : none;

        i,
        svg {
          margin-right : 17px;
        }
      }
    }
  }
}

@media
  (max-width : 575.98px) {
  .wrapper__404 {
    .inner__404 {
      padding : 25px;

      .title__404 {
        font-size   : 74px;
        line-height : 74px;
      }

      .text__404 {
        font-size   : 20px;
        line-height : 28px;
      }
    }
  }
}

// ==========[ DEFAULT CONTACT FORM ]==========

.FormBuilder {
  form {
    .Inputfield {
      .InputfieldHeader {
        margin-bottom : 0;
        color         : $grey;
        font-size     : 14px;
        line-height   : 28px;
      }

      .InputfieldContent {
        input,
        textarea {
          min-height    : 40px;
          border        : 1px solid darken($grey-light, 6%);
          border-radius : 0;
          box-shadow    : none;

          &:focus {
            border  : 1px solid $yellow;
            outline : 0;
          }
        }
      }
    }
  }

  button {
    @include           quart-transition(400ms);
    padding          : 16px 20px 12px;
    color            : $white;
    border           : 2px solid $blue;
    border-radius    : 0;
    background-color : $blue;
    text-transform   : uppercase;
    font-family      : $ff-proxima-nova !important;
    font-size        : 14px !important;
    font-weight      : bold;
    line-height      : 14px;
    letter-spacing   : .15em;
    cursor           : pointer;

    &:hover {
      color            : $white;
      border           : 2px solid lighten($blue, 6%);
      background-color : lighten($blue, 6%);
    }
  }
}

.map {
  width       : 100%;
  padding-top : 100%;
}

// ==========[ BROWSERUPGRADE ]==========

.browserupgrade {
  width            : 100vw;
  padding-top      : 15px;
  padding-bottom   : 15px;
  color            : $white;
  background-color : #eb4d4b;
  text-align       : center;

  a {
    color           : $white;
    text-decoration : underline;

    &:hover {
      text-decoration : none;
    }
  }
}
